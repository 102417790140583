import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Website Wireframe Template | Web Wireframe Template | Uizard"
    category="Website Templates"
    url="/templates/website-templates/website-wireframe/"
    metaDescription="Looking to fast track the wireframing process? Our pre-built website wireframe template is here to expedite your project. Try the template for free."
    description="
    h2:Our website wireframe template: The blueprint for your next web page design
    <br/>
    Looking to fast track the <a: https://uizard.io/wireframing/>UX/UI wireframing</a> process? Our pre-built website wireframe template is here to expedite your project.
    <br/>
    Wireframing with Uizard is the perfect way to create an initial blueprint for your web page or site build, and with our tailor-made web wireframe design template, things just got a whole lot easier. Instead of building your Uizard wireframe from scratch, this template offers a powerful layout for your future designs, straight out of the box.
    <br/>
    h3:Rapidly customize our web wireframe template
    <br/>
    Our website wireframe template gives you everything you need to start wireframing your next website or web page idea, however, we've made sure customization is easier than ever before. Move and edit all the provided pages and components to create the perfect wireframe for your project.
    <br/>
    h3:Teamwork and our website wireframe template
    <br/>
    Here at Uizard, we understand that teamwork really does make the dream work. With the magic of Uizard's smart collaboration features, you and your team can edit and iterate our website wireframe template rapidly to master your own design. 
    <br/>
    Whether you and your team need a wireframe design template ASAP, or you want to work on your <a: https://uizard.io/ux-design/>UX design</a> from the ground up, Uizard is here to help.
    "
    pages={[
      "A landing page template with reusable sections, including but not limited to, testimonials, featured articles, and newsletter subscription",
      'An "About" page that features your story and your team',
      'A "Contact Us" page with a form, complemented by a placeholder for a pinned map on "Find us here"',
    ]}
    projectCode="wn8JxqlGvlHGLQBPOneW"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of Website Wireframe: overview"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of Website Wireframe: about us page"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of Website Wireframe: contact page"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of Website Wireframe: home page"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of website wireframe template"
    faqtitle={"Website wireframe FAQs"}
    faqquestions={[
      {
        question: "What is a website wireframe?",
        answer:
          "A website wireframe is a schematic or blueprint of a website or web page that is typically presented in greyscale with placeholder text and imagery. A wireframe for a website allows the creator to demonstrate to stakeholders the purpose and intent of a website design with minimal effort. The use of a website wireframe can also improve communication and project outcomes, whilst removing ambiguity in the product design process.",
      },
      {
        question: "How to wireframe a website?",
        answer:
          "You can wireframe a website by sketching your design down on a piece of paper or by using specialized wireframing software, such as Uizard. To make sure your wireframe is effective, keep it simple. Start by drawing your core website screens, and then add on-page elements such as page titles, content blocks, and CTAs. You can also add user journeys by including directional arrows between the various screens of your website wireframe.",
      },
    ]}
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/website-wireframe/web-wireframe-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/website-wireframe/web-wireframe-about.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/website-wireframe/web-wireframe-contact.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/website-wireframe/web-wireframe-home.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/website-wireframe/web-wireframe-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
